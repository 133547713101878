<template>

	<!-- Authors Table Card -->
	<div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="mb-3">
						<h5 class="font-semibold m-0">Buat Order</h5>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

						<a-button @click="onClickBack()" size="small" style="margin-right: 10px;">
							<a-icon type="arrow-left" theme="outlined" />
						    Kembali
						</a-button>
						<!-- <a-button type="primary" @click="onSubmit" size="small">
							<a-icon type="plus" theme="outlined" />
						    Simpan
						</a-button> -->
					</a-col>
				</a-row>
			</template>
		    <a-modal v-model="showModalBack" title="Kembali" @ok="navigateBack()">
				<div class="mb-3">
		      		<a-alert class="mt-1" type="warning" message="Data order yang baru dimasukkan di halaman ini akan hilang. Apakah anda yakin?" show-icon></a-alert>
				</div>
		    </a-modal>
		</a-card>
		<br/>
		<a-card :bordered="false" class="header-solid h-full mb-3" :bodyStyle="{padding: 0,}" :key="updateForm">
			<a-col :span="24" class="col-form" style="padding-left: 20px; padding-right: 20px">
				<a-form
					class="login-form"
					id="components-form-demo-normal-login"
					:form="form"
					:hideRequiredMark="true"
				>

				<h6 class="mt-3">Data Pengirim</h6>
				<div class="mt-3" style="border: 1px solid #cad2d3; border-radius: 15px; padding:25px">
					<a-row type="flex" :gutter="[24,24]">
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Nama" :colon="false">
								<a-input 
								v-decorator="[
								'sender_name',
								{ rules: [{ required: true, message: 'Masukkan nama pengirim!' }] },
								]" placeholder="Nama pengirim" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="No Kontak" :colon="false">
								<a-input 
								v-decorator="[
								'sender_contact',
								{ rules: [{ required: true, message: 'Masukkan no kontak pengirim!' }] },
								]" placeholder="No kontak" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Alamat" :colon="false">
								<a-input 
								v-decorator="[
								'sender_addr',
								{ rules: [{ required: true, message: 'Masukkan alamat pengirim!' }] },
								]" placeholder="Alamat pengirim" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<div class="mb-3 mr-1">
								<a-form-item label="Kabupaten" :colon="false">
				    				<LokasiAutoComplete :value="order ? order.from_location.search : ''" @select="(value) => onChangeFromLocation(value)" />
			    					<a-input 
			    						type="hidden"
										v-decorator="[
										'from_id',
										{ rules: [{ required: true, message: 'Masukkan kabupaten asal!' }] },
										]" placeholder="Kabupaten asal" />
				    				<!-- <a-alert class="mt-1" type="error" :message="validation.from_id" show-icon v-if="validation.from_id"></a-alert> -->
				    			</a-form-item>
					    	</div>
						</a-col>
					</a-row>
				</div>

				<br/>
				<h6 class="mt-3">Data Penerima</h6>
				<div class="mt-3" style="border: 1px solid #cad2d3; border-radius: 15px; padding:25px">
					<a-row type="flex" :gutter="[24,24]">
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Nama" :colon="false">
								<a-input 
								v-decorator="[
								'receiver_name',
								{ rules: [{ required: true, message: 'Masukkan nama penerima!' }] },
								]" placeholder="Nama penerima" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="No Kontak" :colon="false">
								<a-input 
								v-decorator="[
								'receiver_contact',
								{ rules: [{ required: true, message: 'Masukkan no kontak penerima!' }] },
								]" placeholder="No kontak" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Alamat" :colon="false">
								<a-input 
								v-decorator="[
								'receiver_addr',
								{ rules: [{ required: true, message: 'Masukkan alamat penerima!' }] },
								]" placeholder="Alamat penerima" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<div class="mb-3 mr-1">
								<a-form-item label="Kabupaten" :colon="false">
				    				<LokasiAutoComplete :value="order ? order.to_location.search : ''" @select="(value) => onChangeToLocation(value)" />
			    					<!-- <a-alert class="mt-1" type="error" :message="validation.to_id" show-icon v-if="validation.to_id"></a-alert> -->
			    					<a-input 
			    						type="hidden"
										v-decorator="[
										'to_id',
										{ rules: [{ required: true, message: 'Masukkan kabupaten tujuan!' }] },
										]" placeholder="Kabupaten tujuan" />
				    			</a-form-item>
					    	</div>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="No Kontak Alternatif" :colon="false">
								<a-input 
								v-decorator="[
								'receiver_contact_alt',
								]" placeholder="No kontak" />
							</a-form-item>
						</a-col>
					</a-row>
				</div>

				<br/>
				<h6 class="mt-3">Detil Order</h6>
				<div class="mt-3" style="border: 1px solid #cad2d3; border-radius: 15px; padding:25px">
					<a-row type="flex" :gutter="[24,24]">
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Tanggal Order" :colon="false">
								<a-date-picker 
								style="width: 100%;"
								v-decorator="[
								'order_date',
								{ rules: [{ required: true, message: 'Masukkan tanggal order!' }] },
								]" placeholder="Tanggal Order" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Volume (m3)" :colon="false">
								<a-input-number
								style="width: 100%;"
								:step="0.1"
								v-decorator="[
								'volume',
								{ rules: [{ required: true, message: 'Masukkan volume!' }] },
								]" placeholder="Volume" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Berat (kg)" :colon="false">
								<a-input-number
								style="width: 100%;"
								:step="0.1"
								v-decorator="[
								'weight',
								{ rules: [{ required: true, message: 'Masukkan berat!' }] },
								]" placeholder="Berat" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item class="mb-1" label="Pilih Service" :colon="false">
								<a-select :default-value="order ? order.service_id : ''" style="width: 100%" @change="onChangeService">
								    <a-select-option :value="service.id" v-for="(service, index) in services" :key="`service_option_${index}`">
								        {{service.name}}
								    </a-select-option>
								</a-select>
								<a-input 
		    						type="hidden"
									v-decorator="[
									'service_id',
									{ rules: [{ required: true, message: 'Pilih service!' }] },
									]" placeholder="Pilih service" />
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item label="Perhitungan Biaya" :colon="false">
								<div>
								    <a-checkbox 
								    v-decorator="[
									'is_volume',
									]"
								    @change="onChangeCalculation()">
									    Berdasarkan Volume
									</a-checkbox>
								</div>
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="6" class="col-form" style="padding: 0px 12px;">
							<a-form-item label="Status Pembayaran" :colon="false">
								<a-checkbox :checked="order && order.is_paid ? true : false" v-decorator="['is_paid']">
							    	Sudah Lunas
							  	</a-checkbox>
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="12" class="col-form" style="padding: 0px 12px;" v-if="checkIfFlagPaid()">
							<a-form-item label="Metode Pembayaran" :colon="false">
								<a-radio-group  
									v-decorator="[
									'payment_type',
									{ rules: [{ required: checkIfFlagPaid(), message: 'Pilih metode pembayaran!' }], initialValue: order.payment_type },
									]"
									button-style="solid">
							        <a-radio-button :value="payment" v-for="(payment, index) in payment_types" :key="`payment_type_${index}`">
							          	{{payment}}
							        </a-radio-button>
							      </a-radio-group>
							</a-form-item>
						</a-col>
					</a-row>
				</div>

				<br/>
				<br/>
				<a-row :gutter="[24,24]">
					<a-col :span="24" :lg="12">
						<div style="display: flex; justify-content: space-between;" >
							<h6>Isi Order</h6>
							<a-button type="link" @click="onClickCreateNew()">
								<a-icon type="plus" theme="outlined" /> Tambah
							</a-button>
						</div>

						<div v-for="(item, index) in items" :key="`items_${index}`" class="mt-3 mb-3">
							<hr style="clear: both;" class="mb-3" v-if="index > 0" />
							<a-col :span="24" :lg="12" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-form-item :class="`mb-1 ${items_validation[index].qty ? 'has-error' : ''}`" label="Qty" :colon="false">
									<a-input-number 
									v-model="item.qty"
									:min="1" placeholder="Kuantitas" style="width: 100%;"/>
								</a-form-item>
								<a-alert class="mt-1" type="error" :message="items_validation[index].qty" show-icon v-if="items_validation[index].qty"></a-alert>
							</a-col>
							<a-col :span="24" :lg="12" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-form-item :class="`mb-1 ${items_validation[index].price ? 'has-error' : ''}`"  label="Harga" :colon="false">
									<a-input
									v-model="item.price"
									placeholder="Harga barang" style="width: 100%;" />
								</a-form-item>
								<a-alert class="mt-1" type="error" :message="items_validation[index].price" show-icon v-if="items_validation[index].price"></a-alert>
							</a-col>
							<a-col :span="24" :lg="18" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-form-item :class="`mb-1 ${items_validation[index].desc ? 'has-error' : ''}`"  label="Deskripsi" :colon="false">
									<a-input 
									v-model="item.desc"
									placeholder="Deskripsi barang"/>
						    	</a-form-item>
						    	<a-alert class="mt-1" type="error" :message="items_validation[index].desc" show-icon v-if="items_validation[index].desc"></a-alert>
							</a-col>
							<a-col :span="24" :lg="6" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-button type="link" danger size="small" class="mt-1" @click="onClickDeleteRow(index)" v-if="items.length > 1">
									<span style="color: red;">
										<a-icon type="delete" theme="outlined" />
									</span>
								</a-button>
							</a-col>
						</div>
					</a-col>
					<a-col :span="24" :lg="12">
						<div style="display: flex; justify-content: space-between;" >
							<h6>Harga</h6>
							<a-button type="link" @click="onClickCreateNewPrice()">
								<a-icon type="plus" theme="outlined" /> Tambah
							</a-button>
						</div>
						<div v-for="(price, index) in prices" :key="`prices_${index}`" class="mt-3">
							<hr style="clear: both;" class="mb-3" v-if="index > 0" />
							<a-col :span="24" :lg="10" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-form-item :class="`mb-1 ${prices_validation[index].name ? 'has-error' : ''}`" label="Nama" :colon="false">
									<a-input 
									v-model="price.name"
									:disabled="index == 0"
									placeholder="Nama" />
						    	</a-form-item>
						    	<a-alert class="mt-1" type="error" :message="prices_validation[index].name" show-icon v-if="prices_validation[index].name"></a-alert>
							</a-col>
							<a-col :span="24" :lg="10" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-form-item :class="`mb-1 ${prices_validation[index].price ? 'has-error' : ''}`" label="Nominal" :colon="false">
									<a-input-number 
									v-model="price.price"
									placeholder="Nominal" style="width: 100%;" />
						    	</a-form-item>
						    	<a-alert class="mt-1" type="error" :message="prices_validation[index].price" show-icon v-if="prices_validation[index].price"></a-alert>
							</a-col>
							<a-col :span="24" :lg="4" :sm="24" class="mb-1" style="padding: 0px 12px;">
								<a-button type="link" danger size="small" class="mt-1" @click="onClickDeleteRowPrice(price.key)" v-if="prices.length > 1 && index !== 0">
									<span style="color: red;">
										<a-icon type="delete" theme="outlined" />
									</span>
								</a-button>
							</a-col>
						</div>
						<a-col :span="24" class="mb-3">
							<div class="mb-3 mr-1 p-1">
								<h6 class="font-regular"><strong>Total harga: {{convertToRupiah()}}</strong></h6>
							</div>
						</a-col>
					</a-col>
				</a-row>
				<a-col :span="24" style="display: flex; align-items: center; justify-content: flex-end" class="mb-3">

					<a-button type="primary" @click="handleSubmit" style="margin-right: 5px;">
						<a-icon type="plus-square" theme="outlined" />
					    Simpan Data
					</a-button>
				</a-col>

				<br/><br/><br/><br/>
				<div v-if="alert.msg">
					<a-alert :message="alert.msg" :type="alert.type" closable></a-alert>
				</div>
				<br/><br/>
				</a-form>
			</a-col>
		</a-card>
	</div>
	<!-- / Authors Table Card -->

</template>

<script>
	import { processMessage, setInitialValue, renderStatusColor, formatRupiah } from '../helpers';
	import LokasiAutoComplete from '../components/LokasiAutoComplete';
	import Vue from 'vue'
	import { watch } from 'vue';
	import moment from 'moment-timezone';

	export default ({
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				payment_types: [],
				services: [],
				showModal: false,
				showModalBack: false,
				selected_row: {},
				alert: {
					type: '',
					msg: ''
				},
				items_validation: [],
				prices_validation: [],
				items: [],
				prices: [],
				order: null,
				new_item_key: 1,
				pricing: {},
				updateForm: false
			}
		},
		components: {
			LokasiAutoComplete
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		mounted(){
			this.retrievePaymentType();
			this.retrieveServiceList()
			this.retrieveOrder()
		},
	 	methods: {
	 		retrieveOrder(){
	 			this.$http.get(process.env.VUE_APP_API + '/order/' + this.$route.params.id)
				.then((res) => {
					this.order = res.data.data
					console.log(this.order)

					this.form.setFieldsValue({
						order_date: this.order.order_date,
						sender_name: this.order.sender_name,
						sender_addr: this.order.sender_addr,
						sender_contact: this.order.sender_contact,
						receiver_name: this.order.receiver_name,
						receiver_addr: this.order.receiver_addr,
						receiver_contact: this.order.receiver_contact,
						receiver_contact_alt: this.order.receiver_contact_alt,
						volume: this.order.volume,
						weight: this.order.weight,
						service_id: this.order.service_id,
						from_id: this.order.from_location_id,
						to_id: this.order.to_location_id,
						is_paid: this.order.is_paid ? true : false,
						payment_type: this.order.payment_type,
					})

					this.order.prices.map((price, index) => {
						this.prices.push({
							key: this.new_item_key,
							name: price.name,
							price: price.price
						})

						this.prices_validation.push(JSON.parse(JSON.stringify({
			 				name: '',
			 				price: '',
			 			})))

						this.new_item_key++
					})

					this.order.items.map((item, index) => {
						this.items.push({
							key: this.new_item_key,
							desc: item.desc,
							qty: item.qty,
							price: item.price,
						})

						this.items_validation.push(JSON.parse(JSON.stringify({
			 				qty: '',
			 				price: '',
			 				desc: ''
			 			})))
						this.new_item_key++
					})

					this.calculateTotalPrice()
					this.updateForm = true
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		onChangeCalculation(){
	 			let datetime = this.form.getFieldValue('order_date')
	 			this.onChangeLocation()
	 		},
	 		checkIfFlagPaid(){
	 			return this.form.getFieldValue('is_paid')
	 		},
	 		onChangeService(value){
	 			console.log(value)
	 			console.log(this.form)
	 			this.form.setFieldsValue({service_id: value})
	 			this.onChangeLocation()
	 		},
	 		onChangeInput(field, value){
	 			this.form.setFieldsValue({field: value})
	 		},
	 		convertToRupiah(){
	 			return formatRupiah(this.calculateTotalPrice(), 'Rp. ')
	 		},
	 		calculateTotalPrice(){
	 			let total = 0;

	 			this.prices.map((price, index) => {
	 				total += (price.price ? parseFloat(price.price) : 0)
	 			})

	 			return total
	 		},
	 		retrieveServiceList(){
	 			this.$http.get(process.env.VUE_APP_API + '/service')
				.then((res) => {
					this.services = res.data.data
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		retrievePaymentType(){
	 			this.$http.get(process.env.VUE_APP_API + '/payment-type/index')
				.then((res) => {
					this.payment_types = res.data.data
					console.log(this.payment_types)
				})
				.catch(err => {
					this.alert = processMessage(err.response)
				})
	 		},
	 		navigateBack(){ this.$router.go(-1) },
			onClickBack(){
 				this.showModalBack = true
	 		},
	 		onChangeItems(){
	 			console.log('ok')
	 			this.items_validation.map((validate, index) => {
					setInitialValue(validate)
				})

				this.validateItems();
	 		},
	 		validateItems(){
	 			let check = true;

	 			this.items.map((item, index) => {
					if(!item.qty){
						this.items_validation[index].qty = 'Masukkan kuantitas'
						check = false
					}

					if(!item.desc){
						this.items_validation[index].desc = 'Masukkan deskripsi'
						check = false
					}

					if(!item.price){
						this.items_validation[index].price = 'Masukkan harga'
						check = false
					}
				})

	 			return check;
	 		},
	 		validatePrices(){
	 			let check = true;

	 			this.prices.map((item, index) => {
					if(!item.price && item.price !== 0){
						this.prices_validation[index].price = 'Masukkan nominal'
						check = false
					}

					if(!item.name){
						this.prices_validation[index].name = 'Masukkan nama'
						check = false
					}
				})

				return check;
	 		},
			handleSubmit(e){
				e.preventDefault();

				this.items_validation.map((validate, index) => {
					setInitialValue(validate)
				})

				this.prices_validation.map((validate, index) => {
					setInitialValue(validate)
				})

				let check_item = true;
				check_item = this.validateItems();

				let check_price = true;
				check_price = this.validatePrices();

				this.form.validateFields((err, values) => {

					if ( !err && check_price && check_item) {

						this.$http.post(process.env.VUE_APP_API + '/order/' + this.$route.params.id, {
							order_date: moment(values.order_date).format('YYYY-MM-DD HH:mm:ss'),
							sender_name: values.sender_name,
							sender_addr: values.sender_addr,
							sender_contact: values.sender_contact,
							receiver_name: values.receiver_name,
							receiver_addr: values.receiver_addr,
							receiver_contact: values.receiver_contact,
							receiver_contact_alt: values.receiver_contact_alt,
							volume: values.volume,
							weight: values.weight,
							service_id: values.service_id,
							from_location_id: values.from_id,
							to_location_id: values.to_id,
							unit_price: this.prices[0].price,
							total_price: this.calculateTotalPrice(),
							is_paid: values.is_paid,
							prices: this.prices,
							payment_type: values.payment_type,
							items: this.items,
							_method: 'PUT'
						})
						.then((res) => {
							let data = res.data.data
							// console.log(data)
							this.alert = processMessage(res)
							setTimeout(() => {
								return this.$router.push({name: 'Order'})
							}, 1500)
						})
						.catch(err => {
							this.alert = processMessage(err.response)
						})
					} else {
						console.log('error');
					}
				});
			},
			onChangeFromLocation(value){ 
				this.form.setFieldsValue({from_id: value.id})
				this.onChangeLocation()
			},
			onChangeToLocation(value){ 
				this.form.setFieldsValue({to_id: value.id})
				this.onChangeLocation()
			},
			onChangeLocation(){
				if(this.form.getFieldValue('from_id') && this.form.getFieldValue('to_id') && this.form.getFieldValue('service_id')){
					this.$http.post(process.env.VUE_APP_API + '/pricing/check', {
						from_location_id: this.form.getFieldValue('from_id'),
						to_location_id: this.form.getFieldValue('to_id'),
						service_id: this.form.getFieldValue('service_id')
					})
					.then((res) => {
						let data = res.data.data
						this.pricing = data
						console.log(data)

						if('price' in data){
							setInitialValue(this.alert)

							//update biaya kirim
							let price = this.pricing.price
							if(this.form.getFieldValue('is_volume')){
								if(this.form.getFieldValue('volume')){
									let volume = parseFloat(this.form.getFieldValue('volume'))
									price = volume * this.pricing.price
								} else {
									this.form.setFieldsValue({volume: 1})
								}
							} else {
								if(this.form.getFieldValue('weight')){
									let weight = parseFloat(this.form.getFieldValue('weight'))
									console.log(weight)
									price = weight * this.pricing.price
								} else {
									this.form.setFieldsValue({weight: 1})
								}
							}

							this.prices[0].price = price
						} else {
							this.prices[0].price = 0
						}
					})
					.catch(err => {
						this.alert = processMessage(err.response)
					})
				}
			},
			onClickDeleteRow(index){
 				this.items.splice(index, 1)
 				this.items_validation.splice(index, 1)
	 		},
	 		onClickCreateNew(){ 
	 			this.new_item_key++

	 			this.items.push(JSON.parse(JSON.stringify({
	 				key: this.new_item_key,
	 				qty: 0,
	 				price: 0,
	 				desc: ''
	 			})))

	 			this.items_validation.push(JSON.parse(JSON.stringify({
	 				qty: '',
	 				price: '',
	 				desc: ''
	 			})))
	 			// this.validation.push(JSON.parse(JSON.stringify(this.new_item)))
	 		},
	 		onClickCreateNewPrice(){ 
	 			this.new_item_key++

	 			this.prices.push(JSON.parse(JSON.stringify({
	 				key: this.new_item_key,
	 				name: '',
	 				price: 0,
	 			})))

	 			this.prices_validation.push(JSON.parse(JSON.stringify({
	 				name: '',
	 				price: '',
	 			})))
	 			// this.validation.push(JSON.parse(JSON.stringify(this.new_item)))
	 		},
	 		onClickDeleteRowPrice(key){
				// Vue.delete(this.prices, index)
				let index = this.prices.indexOf(this.prices.find(price => price.key == key))
				this.prices.splice(index, 1)
				this.prices_validation.splice(index, 1)
	 		},
		}
	})

</script>